import React from "react";

import "./styles.css";

function Index({ projectUrl, imageUrl, projectName, category }) {
  return (
    <a
      href={projectUrl}
      target="_blank"
      rel="noreferrer"
      className="Home__main__projectsSection__projects__project"
    >
      <div className="Home__main__projectsSection__projects__project__imgDiv">
        <img src={imageUrl} alt={projectName} />
        <div className="Home__main__projectsSection__projects__project__imgDiv__categoryDiv">
          <p>{category}</p>
        </div>
      </div>
      <p>{projectName}</p>
    </a>
  );
}

export default Index;
