import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { LanguageProvider } from "./context/languageContext";
import { ProjectsProvider } from "./context/projectsContext";

import Nav from "./components/Nav";
import Footer from "./components/Footer";

import Home from "./pages/Home";
import AllProjects from "./pages/AllProjects";
import PageNotFound from "./pages/PageNotFound";

function App() {
  return (
    <Router>
      <ProjectsProvider>
        <LanguageProvider>
          <Nav />
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => (
                <Home {...props} title="Jochem Groeneweg | Home" />
              )}
            />
            <Route
              path="/projects"
              exact
              render={(props) => (
                <AllProjects
                  {...props}
                  title="Jochem Groeneweg | All Projects"
                />
              )}
            />
            <Route
              path="/projects"
              exact
              render={(props) => (
                <AllProjects
                  {...props}
                  title="Jochem Groeneweg | Alle Projecten"
                />
              )}
            />
            <Route
              path="/"
              render={(props) => (
                <PageNotFound
                  {...props}
                  title="Jochem Groeneweg | Page Not Found"
                />
              )}
              status={404}
            />
          </Switch>
          <Footer />
        </LanguageProvider>
      </ProjectsProvider>
    </Router>
  );
}

export default App;
