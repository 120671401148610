import { createContext, useEffect, useState } from "react";
import detectBrowserLanguage from "detect-browser-language";

import translationNL from "../assets/translations/nl.json";
import translationEN from "../assets/translations/en.json";

const languageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [translations, setTranslations] = useState({});
  const browserLanguage = detectBrowserLanguage();

  useEffect(() => {
    if (browserLanguage.includes("nl")) {
      setTranslations(translationNL);
    } else {
      setTranslations(translationEN);
    }
  }, [browserLanguage]);

  return (
    <languageContext.Provider value={[translations, setTranslations]}>
      {children}
    </languageContext.Provider>
  );
};

export { languageContext, LanguageProvider };
