import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { languageContext } from "../../context/languageContext";
import "./styles.css";

import Logo from "../../assets/images/Logo.png";

function Index() {
  const [translations] = useContext(languageContext);
  const [navActive, setNavActive] = useState(null);
  const [windowSize, setWindowSize] = useState([0, 0]);

  const ulRef = useRef(null);
  const headerBackgroundRef = useRef(null);

  useLayoutEffect(() => {
    setWindowSize([window.innerWidth, window.innerHeight]);
    window.addEventListener("resize", () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
      if (window.innerWidth > 500) {
        setNavActive(false);
      }
    });
    window.addEventListener("orientationchange", () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
      if (window.innerWidth > 500) {
        setNavActive(false);
      }
    });
    return;
  }, []);

  useEffect(() => {
    if (navActive === null) {
      return;
    }
    // If nav is active
    if (navActive) {
      window.scrollTo(0, 0);
      document.querySelector("html").style.overflow = "hidden";
      document.body.style.overflow = "hidden";
      document.querySelector("html").style.touchAction = "none";
      document.body.style.touchAction = "none";

      ulRef.current.style.opacity = 1;
      ulRef.current.style.pointerEvents = "all";
      ulRef.current.style.transform = "translateY(0)";

      if (windowSize[0] <= 500) {
        headerBackgroundRef.current.style.opacity = 1;
        headerBackgroundRef.current.style.transform = "translateY(0)";
      }
    } else {
      document.querySelector("html").style.overflowY = "auto";
      document.body.style.overflowY = "auto";
      document.querySelector("html").style.touchAction = "auto";
      document.body.style.touchAction = "auto";

      ulRef.current.style.opacity = 0;
      ulRef.current.style.pointerEvents = "none";
      ulRef.current.style.transform = "translateY(-150px)";

      headerBackgroundRef.current.style.opacity = 0;
      headerBackgroundRef.current.style.transform = "translateY(-150px)";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navActive]);

  function handleBurgerClick() {
    // If nav is not active
    if (!navActive) {
      // Set nav active
      setNavActive(true);
    } else {
      // Set nav not active
      setNavActive(false);
    }
  }

  return (
    <header>
      <Link className="Nav__logo__a" to="/" onClick={() => setNavActive(false)}>
        <img src={Logo} className="Nav__logo" alt="" />
      </Link>
      <nav className="Nav__nav">
        <ul ref={ulRef} className="Nav__nav__ul">
          <li>
            <Link
              to="/"
              className="underline-a"
              onClick={() => setNavActive(false)}
            >
              Home
            </Link>
          </li>
          <li>
            {/* <Link
              to="/projects"
              className="underline-a"
              onClick={() => setNavActive(false)}
            >
              {translations["NAV_PORTFOLIO"]}
            </Link> */}
            <a href="/#about" className="underline-a">
              Over mij
            </a>
          </li>
        </ul>
        <div className="burger" onClick={handleBurgerClick}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </nav>
      <div className="Nav__header__background" ref={headerBackgroundRef}></div>
    </header>
  );
}

export default Index;
