import React, { useEffect, useState, useContext, useLayoutEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { languageContext } from "../../context/languageContext";
import { projectsContext } from "../../context/projectsContext";

import Project from "../../components/Project";

import "./styles.css";

function Index({ title }) {
  const [translations] = useContext(languageContext);
  const [projects] = useContext(projectsContext);
  const [activeFilter, setActiveFilter] = useState("All");
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    if (activeFilter === "All") {
      setFilteredProjects(projects);
    } else {
      setFilteredProjects(projects.filter((project) => project.category_english === activeFilter));
    }
  }, [activeFilter, projects]);

  useLayoutEffect(() => {
    document.title = title;
  }, []);

  function handleFilterClick(e) {
    setActiveFilter(e.target.attributes["data-category"].value);
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
        </Helmet>
      </HelmetProvider>
      <main className="Allprojects__main">
        <div className="filters">
          <ul>
            <li className={activeFilter === "All" ? "active" : undefined} data-category="All" onClick={handleFilterClick}>
              {translations["ALLPROJECTS_FILTERS_ALL"]}
            </li>
            <li
              className={activeFilter === "Business Website" ? "active" : undefined}
              data-category="Business Website"
              onClick={handleFilterClick}
            >
              {translations["ALLPROJECTS_FILTERS_BUSINESSWEBSITES"]}
            </li>
            <li
              className={activeFilter === "Personal Website" ? "active" : undefined}
              data-category="Personal Website"
              onClick={handleFilterClick}
            >
              {translations["ALLPROJECTS_FILTERS_PERSONALWEBSITES"]}
            </li>
            <li className={activeFilter === "Projects" ? "active" : undefined} data-category="Project" onClick={handleFilterClick}>
              {translations["ALLPROJECTS_FILTERS_PROJECTS"]}
            </li>
          </ul>
        </div>
        <div className="Allprojects__main__projects">
          {projects.length > 0 &&
            filteredProjects.map((project) => (
              <Project
                key={project.id}
                projectUrl={project.url}
                imageUrl={project.imageUrl}
                projectName={project.name}
                category={translations["language"] === "nl" ? project.category_dutch : project.category_english}
              />
            ))}
        </div>
      </main>
    </>
  );
}

export default Index;
