import { createContext, useState, useEffect } from "react";
import { db } from "../firebase";

const projectsContext = createContext();

const ProjectsProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    db.collection("projects")
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => {
        setProjects(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
            category_dutch: doc.data().category_dutch,
            category_english: doc.data().category_english,
            imageUrl: doc.data().imageUrl,
            url: doc.data().url,
          }))
        );
      });
  }, []);

  return (
    <projectsContext.Provider value={[projects]}>
      {children}
    </projectsContext.Provider>
  );
};

export { projectsContext, ProjectsProvider };
