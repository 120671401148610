import React, { useLayoutEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import "./styles.css";

function Index({ title }) {
  useLayoutEffect(() => {
    document.title = title;
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
        </Helmet>
      </HelmetProvider>
      <main className="PageNotFound__main">
        <h1 className="PageNotFound__main__title">Pagina niet gevonden!</h1>
        <Link to="/" className="underline-a PageNotFound__main__goToHomeLink">
          Keer terug naar de Home Pagina
        </Link>
      </main>
    </>
  );
}

export default Index;
