import React, { useContext, useLayoutEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { languageContext } from "../../context/languageContext";
import { projectsContext } from "../../context/projectsContext";
import { Link } from "react-router-dom";

import Project from "../../components/Project";

import sendMail from "./sendMail";

import nerdyProgrammer from "../../assets/images/Nerdy Programmer.png";
import downArrow from "../../assets/images/Icons/down-arrow.png";

import "./styles.css";

function Index({ title }) {
  const [translations] = useContext(languageContext);
  const [projects] = useContext(projectsContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [statusMessage, setStatusMessage] = useState({});

  useLayoutEffect(() => {
    document.title = title;
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage({ status: 102, message: "Aan het verwerken..." });
    const res = await sendMail(name.trim(), email.trim(), message.trim());
    setStatusMessage(res);
    if (res.status === 200) {
      setName("");
      setEmail("");
      setMessage("");
    }
  };

  function returnRightColor(statusCode) {
    switch (statusCode) {
      case 400:
        return "red";
      case 429:
        return "red";
      case 500:
        return "red";
      case 200:
        return "rgb(125, 255, 5)";
      case 102:
        return "#FFB52E";
      default:
        return "red";
    }
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
        </Helmet>
      </HelmetProvider>
      <main className="Home__main">
        <section className="Home__main__heroSection">
          <div className="Home__main__leftSide">
            <div className="Home__main__leftSide__textAndButtons">
              <div className="Home__main__leftSide__textAndButtons__text">
                <h1>{translations["HOME_GREETING_TITLE"]}</h1>
                <p>{translations["HOME_GREETING_TEXT"]}</p>
              </div>
              <a
                href="#contact"
                className="Home__main__leftSide__textAndButtons__contactmeBtn"
              >
                {translations["HOME_GREETING_BUTTON"]}
              </a>
            </div>
          </div>
          <div className="Home__main__rightSide">
            <img
              src={nerdyProgrammer}
              className="Home__main__rightSide__img"
              alt="Nerdy Programmer"
            />
          </div>
          <div className="Home__main__learnMore">
            <a href="#projects">{translations["HOME_LEARN_MORE_TEXT"]}</a>
            <a href="#projects">
              <img src={downArrow} alt="" />
            </a>
          </div>
        </section>
        <section id="projects" className="Home__main__projectsSection">
          <h1 className="Home__main__projectsSection__title">
            {translations["HOME_PROJECTS_TITLE"]}
          </h1>
          <div className="Home__main__projectsSection__projects">
            {projects.length > 0 &&
              projects
                .slice(0, 3)
                .map(
                  (project) => (
                    console.log(project),
                    (
                      <Project
                        key={project.id}
                        projectUrl={project.url}
                        imageUrl={project.imageUrl}
                        projectName={project.name}
                        category={
                          translations["language"] === "nl"
                            ? project.category_dutch
                            : project.category_english
                        }
                      />
                    )
                  )
                )}
          </div>
          {/* Delete */}
          {/* <Link
            to="/projects"
            className="Home__main__projectsSection__allProjectsBtn"
          >
            {translations["HOME_CHECKOUT_ALL_PROJECTS_BUTTON"]}
          </Link> */}
        </section>
        <section className="Home__main__aboutmeSection" id="about">
          <div className="Home__main__aboutmeSection__aboutme">
            <h1 className="Home__main__aboutmeSection__aboutme__title">
              {translations["HOME_ABOUTME_TITLE"]}
            </h1>
            <p className="Home__main__aboutmeSection__aboutme__text">
              {translations["HOME_ABOUT_TEXT1"]}
              <br />
              {/* {translations["HOME_ABOUT_TEXT2"]} */}
              {/* <br /> */}
              <br />
              {translations["HOME_ABOUT_TEXT3"]}
              <hr />
              {translations["HOME_ABOUT_TEXT4"]}
              <hr />
              {translations["HOME_ABOUT_TEXT5"]}
              <hr />
              {translations["HOME_ABOUT_TEXT6"]}
              <hr />
              {translations["HOME_ABOUT_TEXT7"]}
              <hr />
            </p>
          </div>
          <div className="Home__main__aboutmeSection__skills">
            <h1 className="Home__main__aboutmeSection__skills__title">
              {translations["HOME_SKILLS_TITLE"]}
            </h1>
            <div className="Home__main__aboutmeSection__skills__progressbars">
              <div className="Home__main__aboutmeSection__skills__progressbars__progressbar">
                <p className="Home__main__aboutmeSection__skills__progressbars__progressbar__language">
                  HTML
                </p>
                <div className="Home__main__aboutmeSection__skills__progressbars__progressbar__bar">
                  <div style={{ width: "80%" }}></div>
                  <p>80%</p>
                </div>
              </div>
              <div className="Home__main__aboutmeSection__skills__progressbars__progressbar">
                <p className="Home__main__aboutmeSection__skills__progressbars__progressbar__language">
                  CSS
                </p>
                <div className="Home__main__aboutmeSection__skills__progressbars__progressbar__bar">
                  <div style={{ width: "85%" }}></div>
                  <p>85%</p>
                </div>
              </div>
              <div className="Home__main__aboutmeSection__skills__progressbars__progressbar">
                <p className="Home__main__aboutmeSection__skills__progressbars__progressbar__language">
                  JavaScript
                </p>
                <div className="Home__main__aboutmeSection__skills__progressbars__progressbar__bar">
                  <div style={{ width: "90%" }}></div>
                  <p>90%</p>
                </div>
              </div>
              <div className="Home__main__aboutmeSection__skills__progressbars__progressbar">
                <p className="Home__main__aboutmeSection__skills__progressbars__progressbar__language">
                  PHP
                </p>
                <div className="Home__main__aboutmeSection__skills__progressbars__progressbar__bar">
                  <div style={{ width: "80%" }}></div>
                  <p>80%</p>
                </div>
              </div>
              <div className="Home__main__aboutmeSection__skills__progressbars__progressbar">
                <p className="Home__main__aboutmeSection__skills__progressbars__progressbar__language">
                  Node JS
                </p>
                <div className="Home__main__aboutmeSection__skills__progressbars__progressbar__bar">
                  <div style={{ width: "95%" }}></div>
                  <p>95%</p>
                </div>
              </div>
              <div className="Home__main__aboutmeSection__skills__progressbars__progressbar">
                <p className="Home__main__aboutmeSection__skills__progressbars__progressbar__language">
                  React JS
                </p>
                <div className="Home__main__aboutmeSection__skills__progressbars__progressbar__bar">
                  <div style={{ width: "80%" }}></div>
                  <p>80%</p>
                </div>
              </div>
              <div className="Home__main__aboutmeSection__skills__progressbars__progressbar">
                <p className="Home__main__aboutmeSection__skills__progressbars__progressbar__language">
                  Emmet
                </p>
                <div className="Home__main__aboutmeSection__skills__progressbars__progressbar__bar">
                  <div style={{ width: "75%" }}></div>
                  <p>75%</p>
                </div>
              </div>
              <div className="Home__main__aboutmeSection__skills__progressbars__progressbar">
                <p className="Home__main__aboutmeSection__skills__progressbars__progressbar__language">
                  Git
                </p>
                <div className="Home__main__aboutmeSection__skills__progressbars__progressbar__bar">
                  <div style={{ width: "90%" }}></div>
                  <p>90%</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Home__main__contactmeSection" id="contact">
          <div
            className="Home__main__contactmeSection__container"
            style={{ flex: 1 }}
          >
            <div className="Home__main__contactmeSection__details">
              <h1 className="Home__main__contactmeSection__details__title">
                {translations["HOME_CONTACTME_TITLE"]}
              </h1>
              <p className="Home__main__contactmeSection__details__infoText">
                {translations["HOME_CONTACTME_TEXT"]}
              </p>
              <div className="Home__main__contactmeSection__details__iconsWithExtraDetails">
                <a
                  href="tel:+32498774163"
                  className="Home__main__contactmeSection__details__iconsWithExtraDetails__box"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="phone-alt"
                    className="svg-inline--fa fa-phone-alt fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
                  </svg>
                  <p className="Home__main__contactmeSection__details__iconsWithExtraDetails__box__text">
                    +32 498 77 41 63
                  </p>
                </a>
                <a
                  href="mailto:jochem.groeneweg@outlook.com"
                  className="Home__main__contactmeSection__details__iconsWithExtraDetails__box"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="envelope"
                    className="svg-inline--fa fa-envelope fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path>
                  </svg>
                  <p className="Home__main__contactmeSection__details__iconsWithExtraDetails__box__text">
                    jochem.groeneweg@outlook.com
                  </p>
                </a>
                <a
                  href="https://goo.gl/maps/PBtoN5TzjgCxDXxj6"
                  target="_blank"
                  rel="noreferrer"
                  className="Home__main__contactmeSection__details__iconsWithExtraDetails__box"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="map-marker-alt"
                    className="svg-inline--fa fa-map-marker-alt fa-w-12"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                  </svg>
                  <p className="Home__main__contactmeSection__details__iconsWithExtraDetails__box__text">
                    {translations["HOME_CONTACTME_PLACENAME"]}
                  </p>
                </a>
              </div>
              <div className="Home__main__contactmeSection__details__socialmediaIcons">
                <a
                  href="https://github.com/Jochem-Groeneweg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="github"
                    className="svg-inline--fa fa-github fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 496 512"
                  >
                    <path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"></path>
                  </svg>
                </a>
                <a
                  href="https://www.npmjs.com/~jochem-groeneweg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="npm"
                    className="svg-inline--fa fa-npm fa-w-18"
                    role="img"
                    viewBox="0 160 576 224"
                  >
                    <path d="M288 288h-32v-64h32v64zm288-128v192H288v32H160v-32H0V160h576zm-416 32H32v128h64v-96h32v96h32V192zm160 0H192v160h64v-32h64V192zm224 0H352v128h64v-96h32v96h32v-96h32v96h32V192z" />
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/in/jochem-groeneweg-6121a51bb/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="Home__main__contactmeSection__formSide">
            <form
              className="Home__main__contactmeSection__formSide__form"
              onSubmit={handleFormSubmit}
            >
              <div className="Home__main__contactmeSection__formSide__form__container">
                <div
                  className="statusDiv"
                  style={
                    statusMessage.message
                      ? {
                          display: "flex",
                          color: `${returnRightColor(statusMessage.status)}`,
                          border: `0.125rem solid ${returnRightColor(
                            statusMessage.status
                          )}`,
                        }
                      : { display: "none" }
                  }
                >
                  <p>{statusMessage.message}</p>
                </div>
                <div className="Home__main__contactmeSection__formSide__form__fields">
                  <div className="Home__main__contactmeSection__formSide__form__field">
                    <label htmlFor="name">
                      {translations["HOME_CONTACTME_FORM_YOURNAME"]}
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="Home__main__contactmeSection__formSide__form__field">
                    <label htmlFor="email">
                      {translations["HOME_CONTACTME_FORM_YOUREMAIL"]}
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="Home__main__contactmeSection__formSide__form__field">
                    <label htmlFor="message">
                      {translations["HOME_CONTACTME_FORM_MESSAGE"]}
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                </div>
                <div className="Home__main__contactmeSection__formSide__form__container__buttoncontainer">
                  <button type="submit">
                    {translations["HOME_CONTACTME_FORM_SEND_BUTTON"]}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
    </>
  );
}

export default Index;
