import React from "react";

import "./styles.css";

function Index() {
  return (
    <footer>
      <p>Made with &#10084;&#65039; by Jochem Groeneweg</p>
    </footer>
  );
}

export default Index;
